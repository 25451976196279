<template>
	<div class="card-service">
		<div class="service-card-list" v-for="(item, index) in list" :key="index">
			<div class="service-card-head" v-text="item.name"></div>
			<div class="service-cards">
				<div class="service-card" v-for="service in item.values" :key="service.systemid">
					<div class="col">
						<span class="code">卡號：{{service.servicecardnumber}}</span>
						<span class="date">失效日期：{{service.servicecardexpiredate}}</span>
					</div>
					<div class="col">
						<span class="total">{{service.servicecardamount.toFixed(2)}}</span>
						<span class="total-hint">結餘金額</span>
					</div>
					<div class="btn-service-detail" @click="showDetail(service)">消費明細</div>
				</div>
			</div>
		</div>
		<loading v-if="loading"/>
		<service-card-detail v-if="visible" :callback="onClose" :id="detaiId" :title="title"/>
		<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
	</div>
</template>

<script>
	import ServiceCardDetail from '@/layouts/ServiceCardDetail.vue'
	import Loading from '@/layouts/Loading.vue'
	export default {
		components: {
			Loading,
			ServiceCardDetail
		},
		data(){
			return {
				detaiId: null,
				loading: true,
				visible: false,
				style: '',
				title: '',
				list: [],
				isLoadFinished: false,
			}
		},
		mounted: function(){
			this.loadData()
		},
		methods: {
			onClose: function(){
				this.visible = false
				document.body.setAttribute('style', this.style)
			},
			showDetail: function(data) {
				this.detaiId = data.clientservicecardid
				this.title = `卡號：${data.servicecardnumber}`
				this.visible = true
				this.style = document.body.getAttribute('style') || ''
                document.body.setAttribute('style', this.style + 'overflow: hidden;')
			},
			async loadData() {
				this.loading = true
				try {
					var rs = await this.$store.dispatch('getServiceCardList', {
						clientId: this.$store.getters.clientId,
					})
					var list = rs['clientServiceCards']
					this.list = []
					list.forEach((item)=>{
						var filterData = this.list.find(d=>d.name == item.companyshortname)
						if(!filterData) {
							filterData = {
								name: item.companyshortname,
								values: []
							}
							this.list.push(filterData)
						}
						filterData.values.push(item)
					})
					this.loading = false
					this.isLoadFinished = true
				} catch(error) {
					this.loading = false 
					this._toast.warning(this, error)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.card-service {
		position: relative;
		padding-bottom: 60px;
	}

	.service-card-list .service-card-head {
		color: #969696;
		font-size: 18px;
		line-height: 18px;
		font-weight: 400;
		padding-left: 47px;
		position: relative;
		margin-top: 19px;
	}

	.service-card-list .service-card-head::after {
		content: '';
		position: absolute;
		left: 20px;
		top: 0;
		width: 20px;
		height: 18px;
		background-image: url(../../../../assets/imgs/pc/user/img_gongsi@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.service-card-list .service-card {
		display: flex;
		flex-direction: row;
		position: relative;
		width: 770px;
		height: 101px;
		margin-left: 13px;
		margin-top: 20px;
		border-radius: 12px;
		background-color: #fff;
		box-shadow: 2px 5px 5px #F0F1F7, 0px -1px 5px #F0F1F7;
		align-items: center;
	}

	.service-card-list .service-card .col {
		display: flex;
		flex-direction: column;
		margin-left: 168px;
	}

	.service-card-list .service-card .col:nth-child(1) {
		margin-left: 20px;
	}

	.service-card-list .service-card .code {
		color: #242020;
		font-weight: bold;
		font-size: 20px;
		line-height: 20px;
		font-weight: bold;
	}

	.service-card-list .service-card .date {
		color: #969696;
		font-size: 16px;
		font-weight: 400;
		line-height: 15px;
		margin-top: 11px;
	}

	.service-card-list .service-card .total {
		color: #242020;
		font-weight: bold;
		font-size: 20px;
		line-height: 20px;
		font-weight: bold;
	}

	.service-card-list .service-card .total-hint {
		color: #969696;
		font-size: 16px;
		font-weight: 400;
		line-height: 15px;
		margin-top: 11px;  
	}

	.service-card-list .service-card .btn-service-detail {
		position: absolute;
		top: 18px;
		right: 96px;
		padding: 7px 12px 7px 11px;
		line-height: 16px;
		font-size: 16px;
		font-weight: bold;
		color: #fff;
		border-radius: 15px;
		cursor: pointer;
		background:linear-gradient(10deg,rgba(255,128,0,1) 0%,rgba(255,209,66,1) 100%);
	}

	.split-pages-container {
		position: absolute;
		bottom: 20px;
		right: 20px;
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		justify-content: flex-end;
	}

	.split-pages-container > .split-pages {
		display: flex;
		flex-direction: row;
	}

	.split-pages-container > .split-pages > .split-page {
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		color: #969696;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		font-weight: 400;
		margin-right: 20px;
		background-color: #DDDDDD;
		cursor: pointer;
	}

	.split-pages-container > .split-pages > .split-page.more {
		line-height: 16px;
	}

	.split-pages-container > .split-pages > .split-page:hover,
	.split-pages-container > .split-pages > .split-page.current {
		color: #fff;
		background-color: #36C4D0;
		font-weight: bold;
	}

	.split-pages-container > .btn-page-prev,
	.split-pages-container > .btn-page-next {
		height: 20px;
		width: 56px;
		text-align: center;
		line-height: 20px;
		font-weight: 300;
		color: #636363;
		background-color: #DDDDDD;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		cursor: pointer;
	}

	.split-pages-container > .btn-page-next {
		margin-left: 24px;
		color: #231F20;
	}


	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 16px;
		font-weight: 400;
		color: #99999991;
		margin-top: 30px;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}
</style>
